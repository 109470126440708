export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

export const ADD_TOKEN = "ADD_TOKEN";
export const DELETE_TOKEN = "DELETE_TOKEN";

export const ADD_PANTRY_ITEM = "ADD_PANTRY_ITEM";
export const DELETE_PANTRY_ITEM = "DELETE_PANTRY_ITEM";
export const EDIT_QUANTITY = "ADD_QUANTITY";

export const ADD_PREFERENCES = "ADD_PREFERENCES";
export const UPDATE_PREFERENCES = "UPDATE_DIETARY_PREFERENCE";
export const DELETE_PREFERENCES = "DELETE_DIETARY_PREFERENCE";

export const SET_INGREDIENT_SEARCH = "SET_INGREDIENT_SEARCH";
export const CLEAR_INGREDIENT_SEARCH = "CLEAR_INGREDIENT_SEARCH";

export const SET_RECIPE_INFO = "SET_RECIPE_INFO";

export const SAVE_RECIPE = "SAVE_RECIPE";
export const UNSAVE_RECIPE = "UNSAVE_RECIPE";
export const DELETE_RECIPE = "UNSAVE_RECIPE";

export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
